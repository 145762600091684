import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TextMediaSplit = makeShortcode("TextMediaSplit");
const ImageBar = makeShortcode("ImageBar");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TextMediaSplit imagePath="portfolio/it-happened-here/it-happened-here-main.jpg" imageAlt="It Happened Here" mdxType="TextMediaSplit">
      <h1>{`It Happened Here`}</h1>
      <p>{`Sexual assault is a huge, growing problem and not enough is being done to tackle it.`}</p>
      <p>{`I believe that designers have the opportunity to be proactive within their designs by working on projects concerning activism. I have designed this sticker to raise awareness of sexual assault, particularly on women, with the tag-line ‘Be Sexual Assault Aware’. I did this out of frustration and a want to enact awareness of the still growing problem. The idea being that the stickers are placed near or around the area where someone was attacked, to raise awareness of what a huge problem sexual assault is to local populations and also to make establishments take responsibility for the safety of their patrons. `}</p>
    </TextMediaSplit>
    <ImageBar imagesDirectory="portfolio/it-happened-here/lightbox" mdxType="ImageBar" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      